@font-face
	font-family: "svgfont"
	src: url('./../fonts/svgfont.woff2') format('woff2'), url('./../fonts/svgfont.woff') format('woff'), url('./../fonts/svgfont.eot'), url('./../fonts/svgfont.eot?#iefix') format('embedded-opentype'), url('./../fonts/svgfont.ttf') format('truetype')
	font-weight: normal
	font-style: normal

$icon-play: "\EA01"
$icon-pause: "\EA02"
$icon-arrow-right: "\EA03"
$icon-chevron-left: "\EA04"
$icon-chevron-right: "\EA05"
$icon-chevron-down: "\EA06"
$icon-chevron-up: "\EA07"
$icon-compare: "\EA08"
$icon-cart: "\EA09"
$icon-constructor: "\EA10"
$icon-like: "\EA11"
$icon-phone: "\EA12"
$icon-location: "\EA13"
$icon-time: "\EA14"
$icon-mail: "\EA15"
$icon-sent: "\EA16"
$icon-google-play: "\EA17"
$icon-pdf: "\EA18"
$icon-apple: "\EA19"
$icon-toggler: "\EA20"
$icon-close: "\EA21"
$icon-search: "\EA22"
$icon-update: "\EA23"
$icon-check: "\EA24"
$icon-warning: "\EA25"
$icon-cancel: "\EA26"
$icon-share: "\EA27"
$icon-twitter: "\EA28"
$icon-telegram: "\EA29"
$icon-facebook: "\EA30"
$icon-viber: "\EA31"
$icon-whatsapp: "\EA32"
$icon-download: "\EA33"
$icon-magnify: "\EA34"
$icon-info: "\EA35"
$icon-eye-off: "\EA36"
$icon-eye-on: "\EA37"
$icon-play-2: "\EA38"
$icon-user: "\EA39"
$icon-calendar: "\EA40"
$icon-cart-2: "\EA41"
$icon-headphones: "\EA42"
$icon-settings: "\EA43"
$icon-dialog: "\EA44"
$icon-mail-2: "\EA45"
$icon-user-2: "\EA46"
$icon-user-rounded: "\EA47"
$icon-check-rounded: "\EA48"
$icon-filter: "\EA49"
$icon-logout: "\EA50"
$icon-hoods: "\EA51"
$icon-hobs: "\EA52"
$icon-ovens: "\EA53"
$icon-curved-arrow: "\EA54"
$icon-percent: "\EA55"
$icon-minus: "\EA56"
$icon-plus: "\EA57"
$icon-delete-cart: "\EA58"
$icon-edit: "\EA59"
$icon-round-check: "\EA60"
$icon-tracked: "\EA61"
$icon-delivery: "\EA62"
$icon-trash: "\EA63"
$icon-pen: "\EA64"
$icon-personal: "\EA65"
$icon-info-2: "\EA66"
$icon-error: "\EA67"
$icon-fourth: "\EA68"
$icon-chevron-up2: "\EA69"
$icon-settings-2: "\EA70"
$icon-trash-2: "\EA71"


%icon
	font-family: "svgfont"
	font-style: normal
	font-weight: normal
	text-rendering: auto
	speak: none
	line-height: 1
	-webkit-font-smoothing: antialiased
	-moz-osx-font-smoothing: grayscale

.icon, [class^="icon-"], [class*=" icon-"]
	@extend %icon
	display: inline-block

.icon--type_play:before
	content: $icon-play
.icon--type_pause:before
	content: $icon-pause
.icon--type_arrow-right:before
	content: $icon-arrow-right
.icon--type_chevron-left:before
	content: $icon-chevron-left
.icon--type_chevron-right:before
	content: $icon-chevron-right
.icon--type_chevron-down:before
	content: $icon-chevron-down
.icon--type_chevron-up:before
	content: $icon-chevron-up
.icon--type_compare:before
	content: $icon-compare
.icon--type_cart:before
	content: $icon-cart
.icon--type_constructor:before
	content: $icon-constructor
.icon--type_like:before
	content: $icon-like
.icon--type_phone:before
	content: $icon-phone
.icon--type_location:before
	content: $icon-location
.icon--type_time:before
	content: $icon-time
.icon--type_mail:before
	content: $icon-mail
.icon--type_sent:before
	content: $icon-sent
.icon--type_google-play:before
	content: $icon-google-play
.icon--type_pdf:before
	content: $icon-pdf
.icon--type_apple:before
	content: $icon-apple
.icon--type_toggler:before
	content: $icon-toggler
.icon--type_close:before
	content: $icon-close
.icon--type_search:before
	content: $icon-search
.icon--type_update:before
	content: $icon-update
.icon--type_check:before
	content: $icon-check
.icon--type_warning:before
	content: $icon-warning
.icon--type_cancel:before
	content: $icon-cancel
.icon--type_share:before
	content: $icon-share
.icon--type_twitter:before
	content: $icon-twitter
.icon--type_telegram:before
	content: $icon-telegram
.icon--type_facebook:before
	content: $icon-facebook
.icon--type_viber:before
	content: $icon-viber
.icon--type_whatsapp:before
	content: $icon-whatsapp
.icon--type_download:before
	content: $icon-download
.icon--type_magnify:before
	content: $icon-magnify
.icon--type_info:before
	content: $icon-info
.icon--type_eye-off:before
	content: $icon-eye-off
.icon--type_eye-on:before
	content: $icon-eye-on
.icon--type_play-2:before
	content: $icon-play-2
.icon--type_user:before
	content: $icon-user
.icon--type_calendar:before
	content: $icon-calendar
.icon--type_cart-2:before
	content: $icon-cart-2
.icon--type_headphones:before
	content: $icon-headphones
.icon--type_settings:before
	content: $icon-settings
.icon--type_dialog:before
	content: $icon-dialog
.icon--type_mail-2:before
	content: $icon-mail-2
.icon--type_user-2:before
	content: $icon-user-2
.icon--type_user-rounded:before
	content: $icon-user-rounded
.icon--type_check-rounded:before
	content: $icon-check-rounded
.icon--type_filter:before
	content: $icon-filter
.icon--type_logout:before
	content: $icon-logout
.icon--type_hoods:before
	content: $icon-hoods
.icon--type_hobs:before
	content: $icon-hobs
.icon--type_ovens:before
	content: $icon-ovens
.icon--type_curved-arrow:before
	content: $icon-curved-arrow
.icon--type_percent:before
	content: $icon-percent
.icon--type_minus:before
	content: $icon-minus
.icon--type_plus:before
	content: $icon-plus
.icon--type_delete-cart:before
	content: $icon-delete-cart
.icon--type_edit:before
	content: $icon-edit
.icon--type_round-check:before
	content: $icon-round-check
.icon--type_tracked:before
	content: $icon-tracked
.icon--type_delivery:before
	content: $icon-delivery
.icon--type_trash:before
	content: $icon-trash
.icon--type_pen:before
	content: $icon-pen
.icon--type_personal:before
	content: $icon-personal
.icon--type_info-2:before
	content: $icon-info-2
.icon--type_error:before
	content: $icon-error
.icon--type_fourth:before
	content: $icon-fourth
.icon--type_chevron-up2:before
	content: $icon-chevron-up2
.icon--type_settings-2:before
	content: $icon-settings-2
.icon--type_trash-2:before
	content: $icon-trash-2